<style lang="scss">
@import "./assets/css/style.scss";
</style>

<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

  
<script>
  export default {
      name: 'App',
  };
</script>
